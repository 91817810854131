/* transition */
@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

/* transform */
@mixin transform($args...) {
    -webkit-transform: $args;
    -moz-transform: $args;
    -ms-transform: $args;
    -o-transform: $args;
    transform: $args;
}

/* mixin for multiline */
@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 5, $bgColor: $white){
    overflow: hidden;
    position: relative;
    line-height: $lineHeight;
    max-height: $lineHeight * $lineCount; 
    text-align: justify;
    margin-right: -.5em;
    padding-right: 1em;
    &:before {
      content: '...';
      position: absolute;
      right: 0;
      bottom: 0;
    }
    &:after {
      content: '';
      position: absolute;
      right: 0;
      width: 1em;
      height: 1em;
      margin-top: 0.2em;
      background: $bgColor;
    }
  }

@mixin box-shadow($top, $left, $blur,$spread, $color, $inset: false) {
    @if $inset {
      -webkit-box-shadow:inset $top $left $blur $spread $color;
      -moz-box-shadow:inset $top $left $blur $spread $color;
      box-shadow:inset $top $left $blur $spread $color;
    } @else {
      -webkit-box-shadow: $top $left $blur $spread $color;
      -moz-box-shadow: $top $left $blur $spread $color;
      box-shadow: $top $left $blur $spread $color;
    }
}
@mixin for-size($breakpoint, $direction:down) {

  //neu breakpoint ton tai
  @if map-has-key($breakpoints,  $breakpoint) {
      //Kiểm tra breakpoint truyền vào có tồn tại như bạn đã định nghĩa hay không.
      $breakpoint-value: map-get($breakpoints, $breakpoint);

      // Xác định min-width hay max-width
      @if $direction == up {
          //Build media query
          @media (min-width: $breakpoint-value) {
              @content;
          }
      }
      @else if $direction == down {
          //Build media query
          @media (max-width: $breakpoint-value) {
              @content;
          }
      }
  }
  @else {

        @if $direction == up {
            //Build media query
            @media (min-width: $breakpoint) {
                @content;
            }
        }
        @else if $direction == down {
            //Build media query
            @media (max-width: $breakpoint) {
                @content;
            }
        }

    }
}

/* Placeholder */
@mixin placeholder {
    &::-webkit-input-placeholder {@content}
    &:-moz-placeholder           {@content}
    &::-moz-placeholder          {@content}
    &:-ms-input-placeholder      {@content}  
  }


