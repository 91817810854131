.table {
  tr {

    td,
    th {
      font-size: 14px;
      vertical-align: middle;

      background: #fff;
    }
  }
}


.transaction {
  .btn-viewall {
    margin: 0 !important;
    background: #fff;
    padding: 5px 0;
  }
}

%table-style {
  // border: 2px solid $dark-blue;
  border: none;
  border-radius: 5px;
  animation: fadeIn .5s 0.3s ease-out;
  animation-fill-mode: both;
  // box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);

  .table__title {
    text-align: center;
    color: $blue;
    font-size: calc(25/16)*1rem;
    // border-top: 2px solid $dark-blue;
    border-radius: 4px;

    p {
      margin: .5rem auto;
      font-family: myfont_bold;

    }
  }

  .table thead {
    background-color: $dark-blue;

    th {
      vertical-align: middle;
      text-align: left;
      height: 44px;
      color: $white;
      font-weight: normal;
      font-size: 0.8rem;
      white-space: pre;
    }
  }

  .table td {
    vertical-align: middle;
    text-align: left;
    // position: relative;
    // white-space: nowrap;
    font-size: 0.8rem;
    border-top: none;
    // width: 12%;
  }

  .btn--edit {
    padding: 0 0 0 5px;
    background-color: transparent;
    position: absolute;
    right: -4px;
    // top: 50%;
    bottom: -8px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);

    img {
      width: 14px;
    }
  }

  .edit-nav {
    padding: .5rem;
    background-color: transparent;

    img {
      width: 14px;
    }
  }

  .btn--edits {
    padding: 0 0 0 5px;
    background-color: transparent;
    position: absolute;
    left: 6px;
    // top: 50%;
    top: 10px;
    transform: translateY(-50%);

    img {
      width: 14px;
    }
  }

  tbody {
    .btn {
      border: 0;
      font-size: 13px;
    }
  }
}


%btn-icon {
  position: absolute;
  right: 4px;
}

.table {
  margin-bottom: 0;
}

.table-style {
  @extend %table-style;
  margin-bottom: 20px;
  border-top: 0;

  border: 1px solid #E3E9F6;
}

.table-style-2 {
  @extend %table-style;
}

.table thead {
  th {
    font-weight: normal;
  }
}

.sum-property {

  .table td,
  .table th {
    white-space: nowrap;
  }
}

/* Paging Table Total*/

.table-total {
  paging {
    nav {
      // border-top: 2px solid $dark_blue;
    }
  }
}

// Pagination
.pagination {
  // padding: 8px 0;
  // margin-bottom: 0;
  // background: #bfbfbf;
  // border-top-left-radius: 0;
  // border-top-right-radius: 0;

  .page-item {
    &.active {
      .page-link {
        -webkit-transition: all .2s linear;
        transition: all .2s linear;
        border-radius: .125rem;
        background-color: $dark-blue;
        color: $white;
        padding: .3rem .6rem;
      }
    }

    &:first-child {
      .page-link {
        margin-left: 0;
        border-top-left-radius: .25rem;
        border-bottom-left-radius: .25rem;
      }
    }
  }

  &.disabled {
    .page-link {
      color: $gray;
      pointer-events: none;
      cursor: auto;
      background-color: $white;
      border-color: $light-gray;

      &:hover {
        cursor: not-allowed;
      }
    }
  }

  .page-link {
    -webkit-transition: all .3s linear;
    transition: all .3s linear;
    outline: 0;
    border: 0;
    background-color: transparent;
    font-size: .8rem;
    color: $dark-gray;

    &:hover {
      -webkit-transition: all .3s linear;
      transition: all .3s linear;
      border-radius: .125rem;
      color: $blue;
      cursor: pointer;
    }

    &:focus {
      background: transparent;
    }
  }
}


.table-style-sum {

  // &.table-style-sum {
  //   border-bottom: 2px solid #034581;
  // }
  .sum,
  .up,
  .down {
    font-size: 1.1rem !important;
  }
}

.sum-property {

  .table td,
  .table th {
    padding: .5rem;
  }
}

.notice-text {
  font-size: 0.8rem;
  font-style: italic;
}

/* Fix bg table inside table */

td table {
  tr {
    background: transparent !important;
  }
}

/* box download */

.box-download {
  right: 0;
  bottom: 50px;
  top: initial;
  left: initial;
  min-width: 140px;
  padding: 0;
  margin-bottom: 20px;
  box-shadow: 0 0 15px 1px rgba(81, 77, 92, 0.2);
  animation: fadeIn 0.3s forwards;

  li {
    &:hover {
      background: rgb(221, 221, 221);
      @include transition(all ease 0.3s);
    }

    button {
      background: transparent;
      color: $green;
      font-size: 14px !important;

      &:focus {
        color: $green;
      }

      img {
        margin-right: 5px;
      }
    }

    +li {
      border-top: 1px solid #d0d0d0;
    }
  }
}

/* table icon */

.table-icon {
  white-space: nowrap;

  >span {
    width: calc(100% - 25px);
    display: inline-block;
  }

  .btn {
    margin: 0;
  }
}

.sum {

  button,
  button:focus {
    color: $blue !important;
  }
}

.table__title {
  @media only screen and (max-width: 480px) {
    padding-bottom: 10px;

    .no-padding-right {
      padding-right: 15px !important;
    }
  }

  .row {
    margin: 0 10px;
  }
}

.content-search {
  .table__title {
    @media only screen and (max-width: 480px) {
      padding-bottom: 0px;
    }
  }

}


/* Table on tablet landscape */

@media only screen and (min-width: 568px) and (max-width: 1199px) {
  .table-style {
    .table {

      th,
      td {
        font-size: 13px !important;
        white-space: inherit;
        padding-left: 2px;
        padding-right: 2px;
      }

      td.total-sum {
        width: 100px;
      }

      .btn {
        font-size: 11px;
      }
    }

    .hidden-col {
      display: none;
    }
  }

  .table-style-sum {

    .sum,
    .up,
    .down {
      font-size: 1rem !important;
    }
  }
}
