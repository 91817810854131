.modal-backdrop {
  z-index: 999 !important;
}

.modal-header {
  .modal-title {
    color: #202D41;
    font-size: 20px;
    font-style: normal;
    font-family: 'OpenSans6';
    line-height: 24px;
    /* 120% */
  }

  .close {
    position: absolute;
    right: 15px;
    top: 10px;
  }

  h5 {
    color: #202D41;

    font-size: 24px;
    font-style: normal;
    font-family: 'OpenSans6';
    line-height: normal;
    letter-spacing: 0.217px;
  }

  h2 {
    color: #202D41;
    font-size: 20px;
    font-style: normal;
    font-family: "OpenSans6";
    line-height: 24px;
    margin-bottom: 0;
  }
}

.modal-content {
  border-radius: 10px;
}

.buy-product-modal .detail-product-modal .issuer-modal {
  .modal-dialog {
    max-width: 1000px !important;
    max-height: 700px !important;

    .modal-content {
      width: 1000px !important;
      height: 700px !important;
      overflow: auto;
    }
  }
}

// .modal-content {
//   overflow-y: auto;
//   max-height: 700px;
// }

.large-z-index-backdrop {
  z-index: 1056 !important;
}

.small-z-index-backdrop {
  z-index: 1000 !important;
}

.warning-popup-modal {
  .modal-dialog {
    width: 545px;
  }
}

.confirm-popup-modal,
.success-popup-modal,
.warning-popup-modal,
.error-popup-modal,
.notice-modal {
  z-index: 1061 !important;

  .modal-dialog {
    max-width: 545px;

    .modal-header {
      display: none;
    }

    .modal-body {
      padding: 30px 30px 0;

      &-message {
        white-space: normal;
        font-size: 14px;
        line-height: 26px;
        margin-bottom: 1rem;
      }

      &-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;

        img {
          width: 26px;
          height: 26px;
        }

        &-text {
          font-size: 18px;
          font-family: 'OpenSans6';
          font-weight: bold;
          margin-bottom: 0px;
        }
      }
    }

    .modal-footer {
      border: none;
      padding-top: 15px;

      .row {
        margin: 0px;
        // border-top: 1px solid rgba(224, 224, 224, 1);

        div {
          padding: 15px;
        }
      }

      .btn {
        height: 42px;
        cursor: pointer;
      }

      .border-right {
        border-right: 1px solid rgba(224, 224, 224, 1) !important;
      }

      .btn-ok {
        min-width: 139px;
      }

      .btn-close {
        font-weight: bold;
        color: rgba(47, 128, 237, 1);
        cursor: pointer;
      }
    }
  }

}

.info-payment-popup {
  .modal-content {
    max-height: 814px;
  }
}

.view-product-modal {
  .modal-content {
    width: 500px;
  }
}

@media (min-width: 1024px) {}

@media (min-width: 450px) {
  .sell-product-modal {
    .modal-dialog {
      width: 425px !important;
    }

    // .modal-body {
    //   min-height: 495px;
    // }
  }


}


.otp-order-label {
  .otp {
    padding: 0;
    display: block !important;
  }

  .otp-container_form {
    box-shadow: none !important;
    max-width: 100% !important;
  }
}

@media (min-width: 450px) {
  .otp-notice-not-register {
    .modal-content {
      min-width: 430px;
    }
  }
}

@media (min-width: 1200px) {
  .info-payment-popup {
    .modal-dialog {
      min-width: 891px;
      // min-width: 1200px;
    }

    // .modal-content {
    //   width: 1200px;
    // }
  }
}

.detail-ipo-fund {
  .modal-dialog {
    max-width: 757px;
  }
}

.detail-trading-fund {
  .modal-dialog {
    max-width: 1200px;
  }

  .modal-content {
    max-height: 900px;
  }
}

.detail-gold {
  .modal-dialog {
    max-width: 912px;
    min-width: 450px;

    @media (max-width: 480px) {
      width: 95%;
      min-width: 250px;

    }
  }
}

.risk-survey-modal {
  .modal-dialog {
    max-width: 889px;
    width: 100%;

    .modal-content {
      min-height: 570px;
    }
  }
}

.input-income-modal {
  .modal-dialog {
    max-width: 1200px;
    width: 100%;
  }
}

.export-plan-file-modal,
.comfortable-retirement,
.calculate-saving-goal {
  .modal-dialog {
    max-width: 800px;
  }
}

.create-new-plan-popup-modal {
  .modal-dialog {
    max-width: 802px;
  }
}

.input-recommendation-tab-demographic-modal,
.input-recommendation-tab-expense,
.input-evaluation-tab-asset-modal,
.recommendation-protection-modal,
.input-percent-category-for-asset-modal,
.input-percent-category-for-product-modal {
  .modal-dialog {
    max-width: 450px;

    .modal-body {
      overflow-y: auto;
      max-height: 500px;
    }
  }
}

.input-product-for-propose-modal {
  .modal-dialog {
    max-width: 855px;
  }
}

.modal-dialog {
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media(max-width: 768px) {
    min-height: calc(100vh - 20px);
  }

  .modal-content {
    border: none;
  }
}

.extra-large-modal {
  .modal-dialog {
    max-width: 1200px;
  }
}

.large-modal {
  .modal-dialog {
    max-width: 895px;
  }
}

.medium-modal {
  .modal-dialog {
    max-width: 500px;
  }
}

.small-modal {
  .modal-dialog {
    max-width: 545px;
  }
}

.view-image-modal {
  z-index: 1061 !important;

  .modal-dialog {
    max-width: 733px;
    width: 100%;
    height: 562px;

    .modal-header {
      justify-content: flex-end;
      border: none;
    }
  }
}

.edit-agency-modal {
  .modal-dialog {
    max-width: 895px;
  }
}

.view-normal-plan-modal {
  .modal-dialog {
    max-width: 1100px;
    justify-content: flex-start;

    .modal-content {
      border-radius: 15px;
    }
  }

}

.update-profile-normal-plan-modal {
  .modal-dialog {
    max-width: 470px;
  }
}

.config-weight-assessment-item-modal {
  z-index: 1000;

  .modal-dialog {
    max-width: 800px;

    .modal-body {
      max-height: 700px;
      overflow-y: scroll;
    }
  }
}

.send-file-dashboard-modal {
  .modal-dialog {
    max-width: 1000px;
  }
}

// .input-product-for-propose-modal {
//   .modal-body {
//     max-height: 550px;
//     overflow-y: scroll;
//   }
// }

.view-reference-point-modal {
  .modal-dialog {
    max-width: 630px !important;

    .modal-body {
      overflow-y: auto;
      max-height: 700px;
    }
  }
}

.customer-care-modal {
  .modal-dialog {
    max-width: 900px;
  }
}

.config-annual-growth-modal {
  .modal-dialog {
    max-width: 1200px;
    justify-content: flex-start;

    .modal-body {
      min-height: 500px;
    }
  }
}

.clone-comprehensive-plan-modal {
  .modal-dialog {
    max-width: 759px;
  }
}

.view-note-modal {
  .modal-dialog {
    max-width: 420px;
  }
}

.view-comprehensive-plan-modal {
  .modal-dialog {
    max-width: 1300px;
    justify-content: flex-start;
  }
}

.financial-freedom {
  .modal-dialog {
    max-width: 717px;
  }
}
