@import "../global/variables";

/* btn */
%buttonEffect {
  position: relative;
  overflow: hidden;


  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 5px;
    background: rgba(255, 255, 255, .2);
    opacity: 0;
    border-radius: 100%;
    transform: scale(1, 1) translate(-50%);
    transform-origin: 50% 50%;
  }

  &.focus,
  &:focus {
    &:not(:active)::before {
      animation: ripple 1s ease-out;
    }
  }
}

.btn {
  cursor: pointer;

  &.focus,
  &:focus {
    box-shadow: none;
    color: $white;
  }

  &--green {
    background: $green;
    color: $white !important;
    @extend %buttonEffect;

    &:hover {
      // background: darken($green, 3);
      color: $white;
    }
  }

  &--reset {
    background: #e2bc00;
    color: $txt-blue !important;
    @extend %buttonEffect;

    &:hover {
      background: #be9f02;
      color: $white;
    }
  }

  &__blue {
    background: $base-blue;
    color: $white !important;
    @extend %buttonEffect;

    &:hover,
    &:link {
      color: $white !important;
    }

    &--outline {
      background: $white;
      border: 1px solid $base-blue;
      color: $base-blue !important;
    }
  }

  &--fix {
    // padding: .58rem 0.75rem;
    font-size: 14px;
    height: 46px;
  }

  &--red {
    background: $white;
    border: 1px solid $red !important;
    border-radius: 4px;
    color: $red !important;
    @extend %buttonEffect;
    // padding: .3rem;
    width: 100%;

    &:hover,
    &:link {
      // background: darken($red, 3);
      color: $white !important;
    }
  }

  &--grey {
    background: $background-grey;
    border: 1px solid $gray !important;
    border-radius: 4px;
    color: $black !important;
    @extend %buttonEffect;
    // padding: .3rem;
    min-width: 100px;

    &:hover,
    &:link {
      background: $input-border !important;
    }
  }

  &--greens {
    background: $white;
    border: 1px solid $green !important;
    border-radius: 4px;
    color: $green !important;
    @extend %buttonEffect;
    // padding: .3rem;
    width: 100%;

    &:hover,
    &:link {
      // background: darken($green, 3);
      color: $white !important;
    }
  }

  &--white {
    background: $white;
    color: $blue;
    border-color: $blue;

    &:hover,
    &:link {
      // background: darken($white, 8);
      color: $blue;
    }
  }

  &--trans {
    background: transparent;
    color: $green;
    border-color: transparent;

    &:focus {
      color: $green;
    }
  }

  &--darkblue {
    background: $dark-blue;
    border-color: $dark-blue;
    color: $white;

    &:hover,
    &:link {
      background: transparent;
      border: 1px solid $dark-blue;
      color: $dark-blue;
    }
  }

  &--fullWidth {
    width: 100%;
  }
}

.btn-create-acc,
.btn-see-more {
  border-radius: 3px;
  font-size: 1rem;
  padding: 15px 50px;
  border-width: 2px;

  img {
    margin-left: 2px;
    position: relative;
    top: -2px;
  }
}

.btn-create-acc {
  padding: 12px 51px;
  font-size: 1rem;
  margin-top: 7px;
  border-radius: 25px;
}

.btn-see-more {
  animation: fadeInDown .5s 1s;
  animation-fill-mode: both;
  text-transform: uppercase;
}

.btn-buy,
.btn-cancel {
  // min-width: 125px;
  // margin: 0 10px;
  // margin-bottom: 20px;
  text-transform: unset;
  width: 100%;
  padding: .5rem !important;
  font-size: .9rem;
}

.btn-calendar {
  display: flex;

  .fa-calendar {
    background-repeat: no-repeat;
    width: 19px;
    height: 18px;
    vertical-align: middle;

    &:before {
      display: none;
    }
  }
}

// Minh Dao's code
// Button

.btn-approve {
  padding: 0.5rem 1.8rem;
  text-transform: none;
  background-color: $dark-green;
  border: 1px solid $dark-green !important;
  color: $white;

  &:hover {
    background-color: transparent;
    border: 1px solid $dark-green !important;
    color: $dark-green;
  }
}

.btn-accept {
  padding: 0.5rem 1rem;
  text-transform: none;
  background-color: $green;
  border: 1px solid $green !important;
  color: $white;

  &:hover {
    background-color: transparent;
    color: $green;
    border: 1px solid $green !important;
  }
}

.btn-approve-all {
  background-color: $dark-green;
  border: 2px solid $dark-green;
  color: $white;
  width: 100%;
  text-transform: unset;

  &:hover:not([disabled]) {
    background-color: transparent;
    color: $dark-green;
    border: 2px solid $dark-green !important;
  }

  &:disabled {
    background: $dusty-gray;
    border-color: $dusty-gray !important;
  }
}

.btn-accept-all {
  background-color: $green;
  border: 2px solid $green !important;
  color: $white;
  width: 100%;
  text-transform: unset;

  &:hover:not([disabled]) {
    background-color: transparent;
    color: $green;
    border: 2px solid $green !important;
  }

  &:disabled {
    background: $dusty-gray;
    border-color: $dusty-gray !important;
  }
}

.btn-search {
  background-color: $blue;
  border: 2px solid $blue;
  color: $white;
  width: 100%;
  text-transform: unset;

  &:hover:not([disabled]) {
    background-color: transparent;
    color: $blue;
  }
}

.btn-fix .btn {
  padding: 0.5rem 25px 0.5rem 0.5rem;
}

.btn-icon,
.btn-icon:hover {
  background-color: transparent;
  border: none;
  padding: 5px;

  img {
    width: 25px;
  }
}

.btn-marg__top {
  margin-top: 15px;
}

.btn.disabled,
.btn:disabled {
  img {
    opacity: 0.4;
    filter: alpha(opacity=50);
    /* For IE8 and earlier */
  }
}

.btn-outline-success,
.btn-outline-success:focus {
  color: $green;
  background-image: none;
  background-color: transparent;
  border-color: $green;

  &:hover {
    color: #fff;
    background-color: $green;
    border-color: $green;
  }
}

.btn--td_edit {
  background: transparent;
  padding: 0;

  img {
    width: 12px;

    @media (max-width: 480px) {
      position: relative;
      left: -3px;
    }

    @media (max-width: 360px) {
      left: -5px;
      top: -1px;
    }
  }
}

.btn-benefit,
.btn-benefit:hover {
  background: transparent;
  border-color: #ffd235;
  color: #ffd235 !important;
  font-size: 1rem;
  padding: 12px 5px;
  transition: .5s;
  border: 1px solid;
}

.btn__notice {
  cursor: pointer;
  margin-left: 5px;
}
