.show-menu {
  height: 100%;
  overflow: hidden;
  width: 100%;
  position: fixed;
  touch-action: none;
  -ms-touch-action: none;

}

@mixin navbarActive() {
  &.active {
    background-color: #0368FF !important;
    height: 100%;
    z-index: 99;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    animation: inherit !important;

    .navbar-toggler {

      span:nth-child(1),
      span:nth-child(3) {
        @include transition(transform .35s ease-in-out);
      }

      span:nth-child(1) {
        margin-top: 5px;
        @include transform(rotate(135deg));
        opacity: 0.9;
      }

      span:nth-child(2) {
        height: 13px;
        visibility: hidden;
        background-color: transparent;
      }

      span:nth-child(3) {
        margin-top: -23px;
        @include transform(rotate(-135deg));
        opacity: 0.9;
      }
    }
  }
}

#header {
  position: relative;
  width: 100%;
  z-index: 2;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.10);

  &.fixed {
    background-color: $base-blue;
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    z-index: 1030;
    // box-shadow: 0 3px 9px 0 hsla(0, 0%, 0%, 0.3);
    animation: fadeInDown .4s ease;
    animation-fill-mode: both;

    .navbar-brand {
      margin-top: 0;
    }
  }
}

.navbar-brand {
  display: flex;
  align-items: center;
  margin-top: 0px;

  img {
    max-width: 100%;
  }
}

#navbar {
  width: auto;
  margin-right: auto;
}

#navbar .nav-item {
  margin-left: 5px;
}

#navbar a {
  color: $white;
  font-size: 1.1rem;

  &:hover,
  &.active {
    color: $hover-menu
  }

  @media only screen and (max-width: 480px) {
    color: $white !important;
  }
}

#nav-authen {
  list-style: none;
  padding-left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin-bottom: 0px;
  // margin-left: 15px;

  li {
    // float: left;
    // margin-left: 15px;
    // margin-top: 2px;

    a {
      font-size: 16px;
      padding: 0;
      // padding: .6em 1em;
      color: $white;
      // border: 1px solid $white;
      border-radius: 25px;
      text-align: center;
      // min-width: 80px;


      &:hover {
        color: $txt-blue;
      }

      // @media (min-width: 768px) {
      //   min-width: 140px;
      // }
    }
  }

}

home {
  .bootstrap-select.btn-group .dropdown-toggle .filter-option {
    width: auto !important;
  }
}

.search-form {
  margin-top: 62px;
  margin-bottom: 10px;

  button,
  .btn-group {
    width: 100% !important;
    border-radius: 0;
    margin-bottom: 10px;
  }

  .btn-group {
    margin-bottom: 0px;
  }

  button:not(.actions-btn) {
    // padding-top: 15px;
    // padding-bottom: 15px;
    border: 2px solid $base-blue;
    border-radius: 5px !important;

    &:focus {
      outline: none !important
    }
  }

  .bootstrap-select {
    &.show {
      button:not(.actions-btn) {
        box-shadow: inset 2px 0px 5px 1px #e0e0e0;
        outline: none !important
      }
    }
  }

  .filter-option {
    font-size: 1rem;
    vertical-align: middle;
  }

  .btn-header-search {
    font-size: 1rem;
    border: 2px solid $blue !important;
    height: 48px;
    padding-left: 0px;
    padding-right: 0px;

    &:hover {
      border: 2px solid transparent !important;
    }
  }

  .dropdown-toggle.bs-placeholder {
    color: $gray !important;
    background-color: $white;
    text-transform: none;
  }

  .dropdown-toggle {
    background-color: $white;
    color: $blue !important;
    text-transform: uppercase;
  }

  .dropdown-toggle::after {
    border-top-color: $blue;
    font-size: 1.8rem;
    margin-left: -10px;
    background-color: $white;
  }

  /* Bootrap 4 Fixed css */
  .dropdown-menu {
    border: 1px solid $blue;
    margin-top: 0;

    li {
      a {
        color: $black;
      }
    }

    .dropdown-item {
      white-space: normal;
      padding: 5px 15px;
      outline: none !important;

      .text {
        text-transform: uppercase;
      }
    }
  }

  .open .dropdown-menu {
    display: block;

    li {
      padding: 5px 10px;
    }
  }

  .bootstrap-select.btn-group .dropdown-menu li.selected a span.check-mark {
    display: inline-block;
    top: 5px;
    float: right;
    margin-top: 0px !important;
  }

  /* End Bootrap 4 Fixed css */
  .dropdown {
    .dropdown-display-value {
      span {
        font-size: 1.1rem !important;
      }

      &::after {
        content: "";
        width: 15px;
        height: 15px;
        top: 12px;
      }
    }

    .dropdown-content {
      border-radius: .35rem;

      // input {
      //    display: none;
      // }
      a {
        text-transform: uppercase;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  #header {
    .navbar {
      padding: 5px 0;
      justify-content: center;
      align-items: unset;
    }

    .navbar-brand {
      // margin-left: 30px;
      width: 100px;
      margin-top: 0;
    }

    @include navbarActive();

    &.fixed {
      padding: 8px 0;
    }
  }

  #nav-authen {
    position: absolute;
    right: 0;
    top: 6px;
    width: inherit;

    li {
      margin-top: 1px;
      margin-left: 5px;

      a {
        font-size: calc(16/$root)*1rem;
        padding: .4rem .6rem;
      }
    }
  }

  #navbar .nav-item {
    &:first-child {
      margin-top: 20px;
    }

    margin-left: 0px;
    text-align: center;

    a {
      font-size: 1.4rem;
      padding-left: 0;
    }
  }

  #navbar {
    margin-left: -1000px;
    margin-right: -1000px;
    border-top: 1px solid #fff;
    margin-top: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .homePage {
    .search-form {
      margin-top: 30px;

      .btn-header-search {
        margin-bottom: 2px;
      }
    }

    .btn-create-acc,
    .btn-see-more {
      padding: 10px 31px;
    }
  }
}

@media only screen and (max-width: 360px) {
  #header {
    .navbar-brand {
      width: 80px;
    }
  }
}
