@import "../global/font";
@import "../global/button";

html {
  scroll-behavior: smooth;
}

body {
  font-family: OpenSans4;

  background-color: #F7F7F7;
  color: #000;
  -webkit-overflow-scrolling: touch;
  padding: 0;
  margin: 0;
  overflow-x: hidden;

  font-size: 14px;
}

strong {
  font-family: 'OpenSans6';
  font-weight: unset;
}

textarea {
  border-radius: 10px !important;
  font-size: 14px !important;

  &:focus {
    outline: none;
  }
}

.btn-link:focus,
.btn-link:hover {
  text-decoration: none !important;
}

.hidden {
  display: none !important;
}

.custom-disabled {
  opacity: 0.7;
  pointer-events: none;
}

.p-relative {
  position: relative;
}

.w-29 {
  width: 29%;
}

.main {

  // padding-top: 40px;

  @media screen and (max-width:576px) {
    padding: 40px 0;
  }

  &__home {
    height: calc(100vh - 157px);
  }

  // @media screen and (max-width:576px) {

  //   &__register {
  //     height: 100vh !important;
  //   }
  // }
}

input {
  border-radius: 5px !important;
  border: 1px solid var(--components-border-color, #E5E5E5);
  background: #FFF;

  height: 42px;

  &:hover,
  &:focus {
    border-color: #008561 !important;
  }

  &:disabled:hover {
    border-color: #E0E0E0 !important;
  }
}

input[type="month"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url(../../../assets/images/icons/calendar.svg) no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
}

input.calendar {
  background: url(../../../assets/images/icons/calendar.svg) no-repeat;
  background-position: 95% 50%;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #BDBDBD !important;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #BDBDBD !important;
  opacity: 1;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #BDBDBD !important;
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #BDBDBD !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #BDBDBD !important;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #BDBDBD !important;
}

input::placeholder {
  color: gray;
  /* Màu chữ placeholder */
  font-size: 14px;
  /* Kích thước chữ */
  font-style: italic;
  /* Kiểu chữ */
}

input[type=date]:invalid {
  color: lightgray;
}

input:focus::-webkit-datetime-edit {
  color: initial;
}

.form-control:disabled,
.form-control[readonly] {
  cursor: not-allowed;

  &:hover {
    border-color: #E0E0E0 !important;
  }
}

.btn {
  cursor: pointer;
}

.disabled {
  pointer-events: none;
  background-color: #eceeef !important;
}

//Dropdown 

.form-group {

  position: relative;

  label {
    color: #202D41;

    font-family: "OpenSans6";
    font-size: 14px;
  }

  .form-row {
    gap: 27px;

    .dropdown-display-value {
      border-radius: 5px;
      border: 1px solid var(--Gray-5, #E0E0E0);
      background: var(--white, #FFF);

      height: 36px;
    }

    .disabled {
      .dropdown-display-value {
        background-color: #eceeef !important;
      }
    }
  }
}

.common {
  &__nochart {
    color: var(--Gray-3, #828282);
    font-family: "OpenSans4";
    font-size: 14px;
    line-height: 20px
  }

  &__title {
    color: #202D41;
    font-size: 22px;
    font-weight: 400;

    display: block;
    margin-bottom: 20px;

    &--small {
      font-size: 14px;
      font-family: 'OpenSans6';
    }

    &--block {
      color: #202D41;
      font-family: "OpenSans7" !important;
      font-size: 16px !important;
      margin-right: auto;
    }
  }

  &__timeFilter {
    display: flex;
    align-items: center;

    margin-top: 30px;
    margin-bottom: 20px;

    span {
      color: #202D41;
      font-size: 14px;
    }

    .from-date,
    .to-date {
      position: relative;
      margin-left: 15px;

      margin-bottom: 0;

      label {
        position: absolute;
        top: 9px;
        left: 4px;

        color: #828282;
        font-size: 14px;

        margin-bottom: 0;
      }

      input {
        width: 230px;
        padding-left: 5rem;

        border-radius: 4px;
        border: 1px solid var(--components-border-color, #E5E5E5);
      }
    }
  }

  &__table {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid #f2f2f2;

    overflow: inherit;

    table {
      tbody {
        tr.first-child {
          td {
            background: var(--Gray-6, #F2F2F2) !important;
            padding: 6px 15px;
          }
        }
      }

      thead {
        tr {
          th {
            font-size: 12px;

            &.border__th {
              border-bottom: 1px solid #e0e0e0 !important;

              em {
                font-family: 'OpenSans4';
              }
            }
          }
        }
      }

      tfoot {
        tr {
          td {
            font-size: 12px !important;
          }
        }
      }

      tr {
        &:hover {
          td {
            background: #96E2CE;

            .file__status--name {
              background: #EEFAFE !important;
            }
          }
        }

        th {
          background: #F4FFFB;
          border: none;
          border-right: 1px solid #e0e0e0;

          color: #1A9365;

          white-space: nowrap;

          font-size: 14px;
          font-family: 'OpenSans6';

          vertical-align: middle;

          em {
            font-family: 'OpenSans4';
          }

          &:first-child:not(.sub__th) {
            border-top-left-radius: 10px;
          }

          &:last-child:not(.sub__th) {
            border-top-right-radius: 10px;
            border-right: none;
          }

          &.full__th {
            border-bottom: 1px solid #e0e0e0;
            text-align: center;
          }

        }

        td {
          background: #ffffff;

          input {
            height: 28px;

            &::placeholder {
              font-size: 12px;
              font-style: italic;
            }

          }

          select {
            height: 28px;
            padding: 0px 35px 0px 5px;

          }

          input {
            font-size: 14px;
          }

          .add__form {
            margin-top: 0 !important;
          }

          &.active {
            background-color: #96E2CE;
          }
        }

        &.active {
          td {
            background-color: #96E2CE;
          }
        }
      }
    }

    &--footer {
      tr {
        td {
          font-size: 14px !important;
          background-color: #e5f4f0 !important;

          &:first-child {
            border-bottom-left-radius: 5px;
          }

          &:last-child {
            border-bottom-right-radius: 5px;
          }
        }
      }
    }

    td {

      app-error-text {
        em {
          position: absolute;
          z-index: 2;
          margin-bottom: 0;
          // top: 17px;

          // left: 22px;
        }
      }
    }

    &.table__lock {
      border: 1px solid #e0e0e0 !important;

      tr {
        &:hover {
          td {
            background: transparent !important;
          }
        }

        td {
          background: #f2f2f2;
          border: 1px solid #e0e0e0;
        }
      }
    }

    &--noborder {
      tr {

        td {
          border-left: none !important;
          border-right: none !important;
        }
      }
    }
  }

  &__btn {
    border-radius: 25px;
    background: #1A9365;

    color: #ffffff;
    font-size: 14px;
    font-family: 'OpenSans6';

    height: 42px;
    width: 100%;

    padding: 10px 15px;

    &--outline {
      border: 1px solid #1A9365;
      background: #ffffff;
      color: #1A9365;

      border-radius: 50px;
    }

    &:hover {
      background: #04634a;
    }

    &--sub {
      border-radius: 25px;
      background: var(--Gray-6, #F2F2F2);
      border: 1px solid #828282;

      color: var(--Gray-3, #828282);
      text-align: center;

      font-size: 16px;
      font-style: normal;
      font-family: 'OpenSans6';
      line-height: 16px;

      &:hover {
        background: #e4e4e4;
      }
    }

    &--secondary {
      background: #B2E4D1;
      color: #1A9365;

      &:hover {
        color: #ffffff;
      }
    }

    &--link {
      color: #2F80ED;
      background: transparent;

      &:hover {
        color: #2262b5;
        background: transparent;

      }
    }

    img {
      padding-left: 5px;
    }
  }

  &__block {
    border-radius: 5px;
    border: 2px solid var(--Blue-1, #2F80ED);
    background: #EEF5FF;

    padding: 7px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;

    position: relative;

    p {
      color: var(--Gray-3, #828282);

      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }

    label {
      color: var(--Gray-3, #202d41) !important;
      text-align: center;

      font-size: 14px;
      font-style: normal;
      font-family: 'OpenSans7';

      margin-bottom: 0;
    }

    span {
      color: #2F80ED;
      text-align: center;

      font-size: 18px;
      font-style: normal;
      font-family: 'OpenSans7';
      line-height: 16px;

      .unit {
        color: #202D41;

        font-size: 14px;
        font-style: normal;
        font-family: 'OpenSans6';
      }
    }

    &::before {
      content: "";
      position: absolute;
      left: -15px;
      top: unset;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 10px;
      border-color: transparent transparent #2F80ED;
      z-index: 9;
      rotate: 270deg;
    }

    &--custom {
      margin-bottom: 1rem;
      width: 100%;

      height: 40px;
      justify-content: start;
      flex-direction: row;

      &::before {
        top: 13px;
      }

      &-top {
        margin-top: 20px;

        &::before {
          rotate: unset;
          top: -10px;
          left: 50%;
        }
      }
    }



  }

  &__checkbox {
    display: flex;
    align-items: center;
  }

  &__footer--btn {
    display: flex;
    align-items: center;
    gap: 5px;

    .btn {
      max-width: 140px;
    }
  }

  &__block--bg {
    padding: 20px;
    margin-bottom: 20px;

    border-radius: var(--spacing, 10px);
    background: #FFF;

    display: flex;
    flex-direction: column;
    gap: 10px;

    &.add__height {
      min-height: 386px;

    }

    label {
      color: #202D41;

      font-family: "OpenSans7";
      font-size: 16px;

      margin-right: auto;
    }

    p {
      color: var(--Gray-3, #828282) !important;
      font-family: 'OpenSans4' !important;
      text-align: center !important;
      font-size: 14px !important;
      margin-bottom: 0;
    }

    .common__btn {
      max-width: 100% !important;
      width: 100% !important;
      margin-left: unset !important;
      justify-content: center;
    }

    .block__status {
      display: grid;
      grid-template-columns: 11px auto;
      gap: 5px;

      .dot {
        border-radius: 50%;
        display: block;
        width: 11px;
        height: 11px;
        margin-top: 4px;

        &.bad {
          background-color: #EB5757;
        }

        &.average {
          background-color: #F2C94C;
        }

        &.good {
          background-color: #27AE60;
        }
      }

      span {
        color: #202D41;

        font-family: "OpenSans7";
        font-size: 13px;
      }
    }

    .point {
      color: #202D41;

      font-family: "OpenSans7";
      font-size: 26px;
    }

    em {
      color: var(--Gray-3, #828282);
      text-align: center;
      font-family: "OpenSans4";
      font-size: 12px;
      font-style: italic;

    }

    a {
      color: #2F80ED;

      font-family: "OpenSans6";
      font-size: 14px;
    }

    &.customize {
      align-items: center;
      // justify-content: center;
      margin-top: 20px;
      margin-bottom: 20px;

      min-height: 164px;
      max-height: 187px;
      height: 100%;
    }

    .no-data {
      gap: 10px;
      margin-top: 50px;
    }

    &.block-lock {
      background: #f2f2f2;
    }
  }

  &__block--result {
    border-radius: var(--spacing, 10px);
    border: 2px solid #2F80ED;
    background: #EEF5FF;
    padding: 20px;

    margin-bottom: 20px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .element {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 10px;
    }

    label {
      color: #202D41;

      font-family: "OpenSans6";
      font-size: 14px;

      margin-bottom: 0;
    }

    span.item {
      font-size: 20px;
      font-family: 'OpenSans6';
      color: #2F80ED;
    }
  }

  &__tab {
    &.nav {
      .nav-item {
        margin-right: 31px;

        .nav-link {
          color: #828282;


          font-size: 14px;
          font-style: normal;
          font-family: 'OpenSans7';

          border: none;
          background-color: transparent;

          padding: 10px 0;

          position: relative;

          cursor: pointer;

          &.active {
            color: #1A9365;

            &::after {
              content: "";
              width: 100%;
              height: 2px;
              background-color: #1A9365;

              position: absolute;
              bottom: 0;
              left: 0;
            }
          }
        }
      }
    }
  }

  &__note {
    &--title {
      display: flex;
      align-items: center;
      gap: 5px;

      margin-bottom: 12px;

      span {
        color: #202D41;
        font-family: 'OpenSans7';
        font-size: 16px;
      }
    }

    p {
      color: #202D41;
      font-size: 14px !important;
      font-family: 'OpenSans4' !important;
      line-height: 22px !important;

    }
  }

  &__sticky {
    max-height: 800px;
    position: sticky;
    top: 30px;
    overflow-y: scroll;
  }

  &__grayblock {
    display: flex;
    flex-direction: column;

    border-radius: var(--spacing, 10px);
    background: var(--Gray-6, #F2F2F2);
    padding: 20px;

    margin-bottom: 20px;
  }
}

.form-control {
  border-color: #e0e0e0 !important;
}

//Custom table
.border-table {
  border-radius: 5px;
  overflow: unset !important;

  border: 1px solid #E0E0E0;

  margin-bottom: 25px;

  .table {

    tr {
      th {
        color: #1A9365;
        font-family: 'OpenSans7';
        border: none;
        border-left: 1px solid #E0E0E0;
        border-right: 1px solid #E0E0E0;

        &:first-child {
          border-left: none;
        }

        &:last-child {
          border-right: none;
        }
      }

      td {
        border: 1px solid #E0E0E0;

        &:first-child {
          border-left: none;
        }

        &:last-child:not(.td__custom) {
          border-right: none;
        }
      }

      &:hover {
        td {
          background: #96E2CE;

          &.none-hover {
            background: unset;
          }
        }
      }
    }

    tfoot {
      tr {
        td {
          border: none;
          font-size: 18px;
          font-family: 'OpenSans7';
        }
      }
    }
  }

  input-number {
    .input-group {
      input {
        height: 30px !important;
        padding: 5px 20px 5px 7px;
      }

      .input-unit {
        top: 4px !important;
      }
    }
  }
}

.group__btn {
  display: flex;
  align-items: center;
  flex-direction: row;

  gap: 20px;

}

//Color
.green {
  color: #27AE60;
}

.orange {
  color: #ED7F1D;
}

.blue {
  color: #56CCF2;
}

.red {
  color: #EB5757;
}

//
nav.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;

  flex-direction: row;
}

.no-items-position {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 300px;
}

// .font-bold {
//   font-weight: bold;
// }


.flexbox-all {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flexbox-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flexbox-end {
  display: flex;
  align-items: flex-end;
}

.flexbox_between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flexbox-center {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.content__tab--header {
  background: #ffffff;
  padding: 20px 0;
  margin-bottom: 20px;

  width: 100%;

  .customize {
    h2 {
      margin-bottom: 0;
    }

    .common__btn {
      max-width: 168px;
      font-size: 14px;
    }
  }
}


.block-nodata {
  margin-top: 30px;

  display: flex;
  flex-direction: column;
  align-items: center;

  h5 {
    font-family: 'OpenSans7';
    font-size: 16px;
    line-height: 19px;
    color: #323648;
    margin-top: 15px;
  }

  span {
    font-size: 14px;
    line-height: 18px;
    color: #B9B9B9;
  }
}


.input-icon {
  cursor: pointer;
  position: absolute;
  bottom: 12px;
  right: 16px;
}

home {
  .main {
    background: #F2F4F4;
  }
}

.border-red {
  border: 1px solid red !important;
}



/* Define Scroll Broser */

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f2f2f2;
  background-color: #dbdbdb;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #BDBDBD;
  border: 2px solid #BDBDBD;
  border-radius: 5px;
}

button {
  cursor: pointer;

  &:focus-visible,
  &:focus {
    outline: none;
  }
}

button,
button:hover,
button:focus {
  table {
    tbody {
      tr {
        td {
          color: #202D41 !important;
        }
      }
    }
  }
}

.btn--green {
  border-radius: 25px;
  background: #008561;

  color: #ffffff;
  font-size: 16px;
  font-family: 'OpenSans7';
  line-height: 20px;

  height: 46px;
  width: 100%;
}


//Custom checkmark
.labelRadio {
  display: inline-block;
  position: relative;
  padding-left: 31px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 16px;
  white-space: nowrap;
  line-height: 2;
  margin-bottom: 0px;
}

.labelRadio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.labelRadio:hover input~.checkmark {
  background-color: #ccc;
}

.labelRadio input:checked~.checkmark {
  background-color: $white;
  border-color: #1A9365;
}

.labelRadio input:checked~.checkmark:after {
  display: block;
}

.labelRadio .checkmark:after {
  top: 3px;
  left: 3px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #1A9365;
}

.checkmark {
  position: absolute;
  // top: 3px;
  top: -1px;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: $white;
  border-radius: 50%;
  border: 1px solid #727272;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.required {
  color: #D81921 !important;
  font-style: italic !important;
}

.info__right {
  .dropdown-display-value {
    border-radius: 5px;
    border: 1px solid var(--Gray-6, #e0e0e0);
    background-color: #FFF !important;

    // height: 46px;
  }

  .disabled {
    .dropdown-display-value {
      background-color: #eceeef !important;
    }
  }
}

.input-date {
  // display: flex;
  position: relative;

  .input-text {
    background-color: white;
    pointer-events: none;
  }

  .btn-calendar {
    position: absolute;
    right: 0px;
  }
}

#loadingModal {
  .container-image {
    position: fixed;
    top: 40%;
    left: 47%;
    z-index: 1000;
    width: 50px;
    height: 50px;
    background-size: 100% 100%;

  }

  .modal-backdrop {
    opacity: 0.3;
  }
}

.btn__delete--span {
  position: absolute;
  top: -21px;
  right: 0px;
  cursor: pointer;
}

@media screen and (max-width: 576px) {

  #loadingModal>div {
    left: 35%;
  }

}

.input-group {
  position: relative;

  textarea {
    border-radius: 5px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF;
  }

  span {
    position: absolute;
    z-index: 9;
    right: 10px;
    top: 8px;
    font-size: 14px;

    &.input-group-text {
      position: relative;
      top: unset;
      right: unset;

      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      padding: .375rem .75rem;
      margin-bottom: 0;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      text-align: center;
      white-space: nowrap;
      background-color: #e9ecef;
      border: 1px solid #ced4da;
      border-radius: .25rem;
    }

    &.input-group_text {
      font-family: 'OpenSans6';
    }
  }

  &_text:first-child {
    right: unset;
    left: 10px;
  }

  .text-area {
    bottom: 5px;
    top: unset;

    color: var(--Gray-4, #BDBDBD);

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.217px;
  }
}

.status__badge {
  border-radius: var(--spacing, 10px);
  display: flex;
  flex-direction: row;
  background: #FFEEDF;
  gap: 5px;
  color: #202D41;
  text-align: center;

  font-size: 12px;
  font-style: normal;
  font-family: 'OpenSans4';
  line-height: 16px;

  padding: 12px 20px;

  img {
    width: 75px;
    object-fit: contain;
  }

  label {
    margin: 0px;
    font-size: 12px;

    color: #828282;
  }

  strong {
    font-family: 'OpenSans7';
    color: #202D41;
  }

  &--timer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
}

.popover {
  border: none;
  padding: 0;
  top: -9px;

  &-content {
    background: #202D41;
    border-radius: 10px;
    color: #ffffff;
  }
}

.popover.bs-tether-element-attached-bottom::after,
.popover.popover-top::after {
  border-top-color: #202D41;
}

.border-left {
  border-left: none !important;
}

.border-right {
  border-right: none !important;
}

.w-5 {
  width: 5%;
}

.custom-link,
.get-data-test {
  cursor: pointer;
  color: #2F80ED !important;

  font-size: 14px;
  font-style: normal;
  font-family: 'OpenSans6';
  line-height: 22px;
}

.get-data-test {
  display: $display-test;
}

// .form__survey {
//   &--content {
//     margin-top: 30px;
//   }
// }

.progressbar {
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    float: left;
    width: 12%;
    position: relative;
    text-align: center;

    &:before {
      content: "";
      width: 14px;
      height: 14px;
      display: block;
      border-radius: 50%;
      line-height: 27px;
      background: #BDBDBD;
      text-align: center;
      position: relative;
      z-index: 1;
    }

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background: #BDBDBD;
      top: 6px;
      left: -59%;
      z-index: 0;
    }

    &:first-child:after {
      content: none;
    }
  }
}

.progressbar li.active::before {
  background: #3aac5d;
}

.progressbar li.active:after {
  background: #3aac5d;
}

.progress {
  background-color: #f0f0f0;
  width: 100%;
  height: 5px;
  border-radius: 100px;

  &-bar {
    background-color: #008561;
  }
}

#custom {
  .certificate__img {
    gap: 9px;

    &--block {
      width: 97px;
      height: 97px;
    }

    .main-image {
      max-width: 100%;
      object-fit: contain;
      height: 97px;
      padding: 5px;
    }
  }

  .front-drop {
    width: 97px;
    height: 97px;
  }
}

// -----remove number arrow ------ 
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.table__header {
  &--customize {
    border-radius: 8px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF;

    padding: 7px 15px;
  }

  .customize__label {


    span {
      display: none;
    }

    .input-group {
      width: 150px !important;

      &:first-child,
      &:last-child {
        input {
          padding-left: 0 !important;
        }
      }
    }
  }

  .ic__arr {
    display: flex !important;
  }
}

.financial-plan,
.profile-container,
.wrapp__contain {
  min-height: calc(100vh - 180px);
}

.input-group .form-control {
  z-index: 1;
}

.certificate {
  form-certificate-private-information {
    .form__input {
      padding: 25px;
    }
  }
}

.text-ellipsis {
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
  max-height: 200px;
}

.reference__rules--list {
  font-size: 14px;

  h1 {
    font-size: 20px;
  }

  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 16px;
  }

  h4,
  h5 {
    font-size: 14px;
  }

  ul {

    li {
      h1 {
        font-size: 20px;
      }

      h2 {
        font-size: 18px;
      }

      h3 {
        font-size: 16px;
      }

      h4,
      h5 {
        font-size: 14px;
      }
    }
  }
}

.up {
  color: #27AE60 !important;
}

.same0 {
  color: #ef921e !important;
}

.down {
  color: #EB5757 !important;
}

.front-drop {
  background: #00000099;
  border-radius: 5px;
  width: 136px;
  height: 136px;

  position: absolute;
  z-index: 999;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  opacity: 0;
  transition: transform 250ms;

  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;

  &:hover {
    opacity: 1;
  }

}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #f7f7f7;
}

.update__date {
  display: block;
  // margin-bottom: 10px;
  text-align: right;

  color: var(--Gray-3, #202D41);
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 18px;

  em {
    color: #828282;
  }
}

.reference__rules--title {

  color: #202d41;
  font-size: 16px;
  gap: 8px;
  font-style: normal;
  font-family: 'OpenSans7';
  line-height: normal;
  margin-bottom: 14px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  a {
    color: var(--Blue-1, #2F80ED);
    text-align: center;
    font-family: "OpenSans6";
    font-size: 14px;
    text-decoration: none;
  }
}

form-register-counselors {
  .input-date {
    min-width: 100% !important;
    z-index: 0;
  }
}

.register__logo {
  img {
    width: 180px;
  }
}

.plan__row {
  .input-date {
    min-width: 153px !important;
  }

  .form-group {
    input.keyboard-input {
      height: 42px !important;
      top: -1px !important;
    }
  }

  .dropdown-display-value {
    height: 42px;
  }
}


@media screen and (max-width: 768px) {
  .floating__block {
    right: 20px;
  }

  .tooltip {
    left: -33px !important;
  }

  .tooltip.bs-tether-element-attached-bottom .tooltip-inner::before,
  .tooltip.tooltip-top .tooltip-inner::before {
    left: 70%;
  }
}

.content__tab--group {
  .content__tab--groupTitle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
}


.chart-legend {
  &-name {
    max-width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-height: 20px;
  }

  &-color {
    border-radius: 50%;
    width: 15px;
    height: 15px;
  }
}

.form__survey--content {
  .common__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    // width: unset !important;
  }
}

.static__content {
  display: flex;
  gap: 20px;

  .form-group {
    display: flex;
    flex-direction: column;

    label {
      color: var(--Gray-3, #828282) !important;
      font-size: 14px !important;
      margin-bottom: 5px;
    }

    span {
      color: #202D41;
      font-size: 16px;
    }
  }

  .common__block {
    height: 46px;
  }
}

.add__form {
  display: flex;
  align-items: center;
  gap: 5px;

  cursor: pointer;

  // padding: 0 20px;

  img {
    width: 22px;
    height: 22px;
  }

  span {
    color: var(--Blue-1, #2F80ED);
    text-align: center;
    font-size: 14px;
    font-family: OpenSans6;
  }
}

//Line 

.big__line {
  width: 100%;
  height: 8px;
  background: var(--Gray-6, #F2F2F2);
  margin: 20px 0;
}

.small__line {
  width: 100%;
  height: 1px;
  background: var(--Gray-6, #e0e0e0);
  margin: 20px 0;
}

.vertical__line {
  height: 50px;
  width: 1px;
  background-color: #e0e0e0;

  &.blue {
    background-color: #2F80ED;
  }

  &.customize {
    height: auto;
  }
}

.big__form--title {
  color: #202D41;

  font-family: "OpenSans6" !important;
  font-size: 20px !important;

  display: block;
  margin-bottom: 20px;
}

.table__note {
  position: relative;

  display: flex;
  align-items: flex-end;

  p {
    font-size: 12px !important;
    font-family: 'OpenSans4' !important;

    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    margin-bottom: 0;

    max-width: 185px;
  }

  span {
    font-size: 12px;
    font-weight: 400;
    color: #2F80ED;

    cursor: pointer;
    // position: absolute;
    // top: 15px;
    // right: -20px;

    margin-left: auto;
  }
}

.file__note {
  max-width: 296px;
}

.no-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .data-message {
    color: var(--Gray-3, #828282);
    font-family: "OpenSans4";
    font-size: 14px;
  }

  p,
  span {
    line-height: 20px;
  }
}

.table__group--btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
}

.text__blue {
  color: var(--Blue-1, #2F80ED);
  font-family: "OpenSans6";
  font-size: 14px;

  display: block;

  cursor: pointer;

  img {
    margin-right: 5px;
  }
}

.form__input {
  border-radius: 10px;
  background: #FFF;
  padding: 20px;

  &--block {
    background: transparent;
    padding: 0;
  }

  label {
    font-size: 14px;
    color: #202D41;
    font-family: "OpenSans6";
  }

  .labelRadio {
    color: #202D41;
    font-size: 14px;
    font-style: normal;
    font-family: "OpenSans6";
    line-height: normal;
  }

}

.group__date {
  .input-date .keyboard-input {
    height: 42px !important;
  }

  .input-date {
    input {
      top: -2px;
    }
  }
}

.form-group-input {
  input-number {
    input {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
}

.input__customize {
  input-number {
    input {
      padding-left: 135px;
    }
  }
}

.input-note-customize {
  input-note {
    textarea {
      background: #ffffff !important;
    }
  }
}

// --------
.accordion-button {
  position: relative;

  span {
    color: #202D41;
    font-size: 16px;
    line-height: 22px;
  }
}

.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url(../../../assets/images/icons/ic-arr-up.svg);
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform .2s ease-in-out;

  position: absolute;
  left: 0px;
  top: 11px;
}

.accordion-button:not(.collapsed)::after {
  background-image: url(../../../assets/images/icons/ic-arr-down.svg);
}

.warning-popup-modal {
  .popup-message {

    p {
      margin-bottom: 0;
    }
  }
}

.nodata {
  text-align: center;

  span {
    color: var(--Gray-3, #828282);
    font-size: 14px;
    line-height: 20px;

    display: block;
    margin: 10px 0;
  }
}

.form-group__title {
  display: flex;
  align-items: center;
  gap: 5px;

  position: relative;

  label {
    margin-bottom: 0;
  }

  span {

    &.tooltips {
      margin-bottom: 0.25rem;
    }

    &:hover {
      svg {
        path {
          fill: #202D41;
        }
      }
    }
  }
}

tab-survey-property-debt {
  dropdown {
    .dropdown-display-value {
      height: unset;

      span {
        font-size: 14px;
      }
    }
  }
}

.tooltip-inner {
  max-width: 300px;
  white-space: normal;
  text-align: left;
}

.form-group__title {
  label {
    margin-right: unset;
  }

  position: relative;

  .unit {
    color: var(--Gray-3, #828282);
    font-size: 10px;
    font-style: italic;

    margin-left: auto;

    // position: absolute;
    // top: 0px;
    // right: 0;

    em {
      color: #202D41;
      font-size: 10px;
    }
  }
}

.no-chart {
  img {
    margin: 0 auto;
  }
}

.insurance__equal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  border-radius: var(--spacing, 10px);
  background: #E6FFE2;
  padding: 20px;

  min-height: 204px;

  // margin-top: 20px;

  img {
    width: 47px;
  }

  label {
    color: #202D41;
    font-family: "OpenSans7";
    font-size: 16px;

    span {
      color: #1A9365;
    }
  }
}

.cost__result--close {
  .insurance__equal {
    margin-bottom: 30px;
  }
}

.customize__table {
  .dropdown-display-value {
    height: 31px !important;

    span {
      font-size: 14px !important;
    }

    &::after {
      width: 21px !important;
      height: 21px !important;
      right: 0 !important;
      top: unset !important;
    }
  }
}

.block__content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;



  &--small {
    padding: 10px 20px;

    border-radius: var(--spacing, 10px);
    cursor: pointer;
    background: #FFF;

    display: flex;
    flex-direction: column;
    gap: 5px;

    // min-width: 338px;
    width: 100%;

    &.active {
      border: 2px solid #1A9365;
      background: #F4FFFB;
    }

    img {
      width: 28px;
      height: 28px;
    }


    span.title {
      color: var(--Gray-3, #828282);

      font-family: "OpenSans7";
      font-size: 16px;
      line-height: 24px;

      margin-left: 5px;
    }

    span.update__date {
      text-align: left;
    }
  }

}

.form__title--name {
  font: 16px OpenSans7;
  color: #202D41;
  display: block;

  margin-bottom: 10px;
}

.tooltip__parent {
  display: flex;
  align-items: center;
  cursor: pointer;
}

//Off canvas
// .offcanvas-active {
//   overflow: hidden;
// }

.screen-overlay {
  width: 0%;
  height: 100%;
  z-index: 30;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(34, 34, 34, 0.6);
  transition: opacity .2s linear, visibility .1s, width 1s ease-in;
}

.screen-overlay.show {
  transition: opacity .5s ease, width 0s;
  opacity: .5;
  width: 100%;
  visibility: visible;
}

#stock-group {
  margin-top: 5px;

  textarea {
    min-height: 450px !important;
  }
}

#gold-group {

  // margin-top: 115px;
  textarea {
    min-height: 1362px !important;
  }
}

#bank-rate-group {

  // margin-top: 100px;
  textarea {
    min-height: 450px !important;
  }
}

#bds-group {

  // margin-top: 100px;
  textarea {
    min-height: 300px !important;
  }
}

#economy-group {
  textarea {
    min-height: 100px !important;
  }
}

span.tooltip {
  display: flex;
  align-items: center;
}

.notice__message {
  font-size: 14px;
  font-style: italic;
  color: #828282;
}

// ---------------------------------------

tool-calculate-life-insurance-popup {
  .common__block--custom-top {
    justify-content: center;
  }
}

.data-evaluation-tab-demographic {
  input-evaluation-popup {
    input-note {
      textarea {
        background: #ffffff !important;
      }
    }
  }
}

propose-product-for-invest-popup {
  form-input-asset-allocation {
    .form__input {
      border-radius: 10px;
      border: 1px solid var(--Gray-5, #E0E0E0);
      background: #FFF;

      margin-bottom: 30px;
    }
  }
}

normal-plan-evaluate-point {

  tab-final-normal-demographic,
  tab-final-normal-property-debt,
  tab-final-normal-retire {
    .plan__content--block {
      margin-top: 20px;
    }

    .plan__content--group {
      grid-template-columns: repeat(4, 25%) !important;
    }
  }
}

.point-y {
  font-size: 10px;
  color: #828282;
  font-style: italic;
  margin-left: 25px;
}

.table-responsive {
  overflow-x: auto !important;
}

.chart__note {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  margin-top: -20px;

  span {
    color: #202D41;
    font-family: "OpenSans6";
    font-size: 14px;
  }
}

edit-comfortable-retirement-popup,
edit-financial-freedom-popup,
edit-calculate-saving-goal-popup {

  detail-info-saving-calculate,
  form-info-cost-retirement,
  form-info-accumulate-for-goal {
    .cost__result--data {
      background: transparent !important;
      padding: 0 !important;
    }
  }
}

tool-calculate-saving-goal-popup {
  detail-info-saving-calculate {
    .cost__result--data {
      background: transparent !important;
      padding: 0 !important;
    }
  }
}

tab-final-retire,
tab-final-financial-protection {
  summary-asset-allocation-total {
    .form__input {
      padding: 0 !important;
      background: transparent !important;
    }
  }
}

form-social-insurance-payment-period {
  app-error-text {
    div {
      position: absolute;
      bottom: -22px;
    }
  }
}

view-comprehensive-plan-popup {
  tab-final-common {
    .plan__content--block {
      margin-top: 0 !important;
    }
  }
}

tab-collect-normal-demographic {
  input-date {

    .form-control:disabled,
    .form-control[readonly] {
      background-color: transparent !important;
      cursor: pointer;
    }
  }
}

step-1-private-information {
  input-date {
    button {
      right: 3px !important;
    }
  }
}

.chart-legend-status {
  span {
    overflow: hidden;
    text-overflow: ellipsis
  }
}

summary-asset-allocation-total {
  pie-chart-comprehensive-plan {
    canvas {
      width: 190px !important;
    }


  }
}

form-input-propose-build-property-debt {
  .common__table td.error-spec app-error-text {
    div {
      text-align: right !important;

      em {
        position: relative !important;
      }
    }
  }
}
