/*--footer Homepage---*/
.col-one,
.col-two,
.col-three {
  margin-top: 3rem;
}

.col-two p,
.col-three p {
  padding-top: 10px;
  color: $black;
  font-size: calc(14/16)*1rem;
  margin-bottom: .5rem;
}

.col-one p {
  margin-top: 0px;
  max-width: 600px;
  margin-bottom: 0rem;
  opacity: .7;
}

.col-four p {
  margin-bottom: .5rem;
  // opacity: .7;
}

.col-two>ul>li>a,
.col-three>ul>li>a {
  color: $black;
  font-size: 1rem;

  &:hover {
    color: $blue;
  }
}

.col-four {
  color: $black;
  margin-bottom: 20px;

  span {
    font-family: 'OpenSans6';
  }

  ul {
    padding: 0;

    li {
      p {
        margin: 0;
        color: $black !important;
      }
    }
  }

  .fa {
    font-size: 1.2rem;
  }
}

.col-five {
  p {
    color: $blue;
  }
}

figure .img-responsive {
  max-width: 100px;
}

footer ul li {
  list-style-type: none;
  padding: 3px 0;
}

#footer-home {
  background-color: $white;
  color: $black;
  font-size: calc(14/16)*1rem;
}

.footer__social {
  padding: 0;
}

.footer__social li a {
  display: inline-block;
  margin: 0 18px 0 0;
}

/* Footer Main */
#footer {
  // background: $base-blue;
  color: $black;
  // margin-top: 100px;
  // position: absolute;
  // bottom: 0;
  // height: 50px;
  // width: 100%;

  .copyright {
    // color: $white;
    padding: 10px;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  .language {
    .dropdown-toggle {
      background: transparent;
      font-size: 12px;
      color: $blue;
      margin-top: 8px;
      text-transform: none;
    }
  }
}
